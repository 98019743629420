<template>
    <div>
        <Traders
            :articleId="this.articleId"
            :customTitle="'Użytkownicy którzy wyświetlili ofertę'"
        >
    </Traders>
    </div>
</template>

<script>

import Traders from '@/views/UserManagement/Traders'

export default {
  name: 'ArticleStatistics',
  data: () => ({
    articleId: ''
  }),
  components: {
    Traders
  },
  beforeMount () {
    this.articleId = this.$route.params.id
  }
}
</script>
